import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/layout/DangerouslySetInnerHtml';
import Breadcrumbs from 'gatsby-theme-husky/src/components/Breadcrumbs';
import Button from 'gatsby-theme-husky/src/common/Button';
import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';
import { IBannerTextSection } from 'gatsby-theme-husky/src/components/BannerTextSection/models';

import 'gatsby-theme-husky/src/components/BannerTextSection/BannerTextSection.scss';
import 'gatsby-theme-husky/src/components/BannerTextSection/BannerTextSectionExtends.scss';

const BannerTextSection: FC<IBannerTextSection & { headerLevel?: number }> = ({
  title,
  description,
  button,
  url,
  headerLevel = 1,
}) => {
  const { isMobile } = useScreenRecognition();

  return (
    <div className="banner-text">
      {url && !isMobile ? (
        <div className="banner-text__breadcrumbs">
          <Breadcrumbs url={url} light />
        </div>
      ) : null}
      <div className="banner-text-wrapper">
        {headerLevel === 2 ? (
          <h2 className="banner-text__title">{title}</h2>
        ) : (
          <h1 className="banner-text__title">{title}</h1>
        )}
        <DangerouslySetInnerHtml html={description} className="banner-text__description" />
        {button?.[0]?.properties ? (
          <Button
            link={button[0].properties.buttonLink[0].url}
            btnColorVariant="transparent-white"
            ariaLabel={button[0].properties.ariaLabel}
          >
            {button[0].properties.buttonText}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default BannerTextSection;
